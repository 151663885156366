// extracted by mini-css-extract-plugin
export var back = "footer-module--back--2ec32";
export var contact = "footer-module--contact--7c331";
export var contain = "footer-module--contain--01876";
export var copyright = "footer-module--copyright--e5100";
export var email = "footer-module--email--21051";
export var enContain = "footer-module--enContain--f9c90";
export var footer = "footer-module--footer--03d8a";
export var split = "footer-module--split--1691a";
export var weChat = "footer-module--weChat--2cc4b";
export var weChatDesc = "footer-module--weChatDesc--8c596";