import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import * as style from './mobileMenu.module.css'
export function MobileMenu({ onTab }) {
    return (
        <div className={style.mobileMenu} onClick={onTab}>
            <StaticImage
                src="../../../images/ui/menu.svg"
                placeholder="blur"
                alt="menu icon"
                width={36}
                objectFit="contain"
            />
        </div>
    )
}
