import * as sectionStyle from '../section.module.css';
import * as style from './blocks.module.css';
import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { chainList1, chainList2, chainList3 } from '../chains.js';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import BtnBeta from './btnBeta';
import BtnNormal from './btnNormal';
// import { useClickAway } from 'react-use';
import { useRef } from 'react';
import { getEditorBaseUrl } from '../../../utils/index.js';

export function Blocks() {
  const { language } = useI18next();
  const ideLang = useMemo(() => {
    return language === 'zh-CN' ? 'zh' : 'en';
  }, [language]);
  const ref = useRef();
  // const [showModal, setShowModal] = useState(false);
  // useClickAway(ref, () => {
  //   setShowModal(false)
  // })
  return (
    <section className={sectionStyle.section}>
      <h2 className={sectionStyle.sectionHeader}>
        <Trans>intro</Trans>
      </h2>
      <p className={sectionStyle.sectionContent}>
        <Trans>start</Trans>
      </p>
      <ul className={`${style.blockList} ${style.moveLeft}`}>
        {chainList1.map((chain) => {
          return (
            <li key={chain.key}>
              <span
                onClick={() => {
                  window.open(
                    `${getEditorBaseUrl()}${chain.link}?language=${ideLang}`,
                    '_blank'
                  );
                }}
              >
                <div className={style.block}>
                  {chain.img}
                  <span>{chain.name}</span>
                </div>
              </span>
            </li>
          );
        })}
      </ul>
      <ul className={`${style.blockList} ${style.moveRight}`}>
        {chainList2.map((chain) => {
          return (
            <li key={chain.key}>
              <span
                onClick={() => {
                  window.open(
                    `${getEditorBaseUrl()}${chain.link}?language=${ideLang}`,
                    '_blank'
                  );
                }}
              >
                <div className={style.block}>
                  {chain.img}
                  <span>{chain.name}</span>
                </div>
              </span>
            </li>
          );
        })}
      </ul>
      <ul className={`${style.blockList} ${style.moveLeftSix}`}>
        {chainList3.map((chain) => {
          return (
            <li key={chain.key}>
              <span
                onClick={() => {
                  window.open(
                    `${getEditorBaseUrl()}${chain.link}?language=${ideLang}`,
                    '_blank'
                  );
                }}
              >
                <div className={style.block}>
                  {chain.img}
                  <span>{chain.name}</span>
                </div>
              </span>
            </li>
          );
        })}
      </ul>
      <div className={style.tryParent}>
        <span
          className={cn(style.btn, style.btnNormal)}
          onClick={() => {
            window.open(
              `${getEditorBaseUrl()}/dashboard/projects?language=${ideLang}`,
              '_blank'
            );
          }}
        >
          <Trans>try</Trans>
        </span>
      </div>
      {/* <div ref={ref} className={cn(style.modalContainer, showModal ? style.modalContainerShow : style.modalContainerHide)}>
        <svg onClick={() => {
          setShowModal(false)
        }} className={style.modalCloseBtn} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.707107 5.25446e-07L12.7279 12.0208L12.0208 12.7279L0 0.707107L0.707107 5.25446e-07Z" fill="#D9D9D9" />
          <path d="M0 12.0208L12.0208 0L12.7279 0.707107L0.707107 12.7279L0 12.0208Z" fill="#D9D9D9" />
        </svg>

        <h3 className={style.modalTitle}><Trans>try.modal.title</Trans></h3>
        <p className={style.modalDesc}>
          <Trans>try.modal.desc</Trans>
        </p>
        <div className={style.modalBtnContainer}>
          <a target="__blank" href={`https://staging-10544d32c0.chainide.com/s/dashboard/projects?language=${ideLang}`}>
            <BtnBeta />
          </a>
          <a
            target="_blank"
            href={`/s/dashboard/projects?language=${ideLang}`}>
            <BtnNormal />
          </a>
        </div>
      </div> */}
    </section>
  );
}
