import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Header } from '../components/header/header.js';
import { Blocks } from '../components/sections/blocks/blocks.js';
import { Features } from '../components/sections/features/features.js';
import { ConsortiumChains } from '../components/sections/chains/consortiumChains.js';
import { PublicChains } from '../components/sections/chains/publicChains.js';
import { Statistics } from '../components/sections/statistics/statistics.js';
import { News } from '../components/sections/news/news.js';
import { Partnerts } from '../components/sections/partners/partners';
import { Footer } from '../components/footer/footer';
import { SEO } from '../components/seo';
import * as Style from '../styles/main.module.css';

export default function Home() {
  const { t } = useI18next();

  return (
    <div>
      <SEO
        title={t('website.title')}
        description={t('website.description')}
        keywords={t('website.keywords')}
      />
      <div className={Style.pageHeader}>
        <Header />
        <Blocks />
      </div>

      <div className={Style.pageMain}>
        <Features />
        <ConsortiumChains />
        <PublicChains />
        <Statistics />
        <News />
        <Partnerts />
        <Footer />
      </div>
    </div>
  );
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
