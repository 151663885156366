import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import * as Style from './footer.module.css';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export function Footer(props) {
  const { t } = useTranslation();
  return (
    <div className={Style.footer}>
      <div
        className={t('menu.lang') === 'en' ? Style.enContain : Style.contain}
      >
        <div>
          <div className={Style.contact}>
            <Trans>footer.contact</Trans>
          </div>
          <div className={Style.email}>
            <Trans>footer.email</Trans>
            <span>contact@whitematrix.io</span>
          </div>
          <div className={Style.copyright}>
            <span>
              Copyright &copy;2021 <Trans>footer.copyright</Trans>
            </span>
            {t('menu.lang') !== 'en' && (
              <>
                <span className={Style.split}>|</span>
                <span className={Style.back}>
                  <a
                    href="https://beian.miit.gov.cn/"
                    target="_blank"
                    style={{ display: 'inline-block' }}
                    rel="noreferrer"
                  >
                    苏ICP备2020056713号-3
                  </a>
                </span>
              </>
            )}
          </div>
        </div>
        {t('menu.lang') !== 'en' && (
          <div>
            <div className={Style.weChat}>
              <StaticImage
                src="../../images/wechatpa.png"
                placeholder="none"
                alt="WeChat Code"
                width={85}
              />
            </div>
            <div className={Style.weChatDesc}>
              <Trans>footer.weChat</Trans>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
