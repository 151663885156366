import React from 'react';
import * as style from './mobileMenuContent.module.css';
import {
  Link,
  useI18next,
  Trans,
  useTranslation
} from 'gatsby-plugin-react-i18next';

const langMap = { en: 'ENGLISH', 'zh-CN': '中文' };
export function MobileMenuContent({ show }) {
  const { t } = useTranslation();
  const { languages, originalPath } = useI18next();
  const current = t('menu.lang');
  return (
    <ul className={`${style.mobileMenuContent} ${show ? style.show : ''}`}>
      <li>
        <a href="https://chainide.com/events/">
          <span>
            <Trans>menu.events</Trans>
          </span>
        </a>
      </li>
      {/* <li>
        <a href={t('menu.incubator.url')} rel="noreferrer" target="_blank">
          <span>
            <Trans>menu.incubator</Trans>
          </span>
        </a>
      </li> */}
      <li>
        <a href={t('menu.document.url')} rel="noreferrer" target="_blank">
          <span>
            <Trans>menu.document</Trans>
          </span>
        </a>
      </li>
      <li>
        <a href={t('menu.forum.url')} rel="noreferrer" target="_blank">
          <span>
            <Trans>menu.forum</Trans>
          </span>
        </a>
      </li>
      {languages.map((lng) => (
        <li key={lng} className={current === lng ? style.active : ''}>
          <Link to={originalPath} language={lng}>
            {langMap[lng]}
          </Link>
        </li>
      ))}
    </ul>
  );
}
