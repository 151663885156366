import Slider from 'react-slick';
import { Jumbotron } from './jumbotron/jumbotron.js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as sectionStyle from '../section.module.css';
import * as chainStyle from './chains.module.css';
import { Cards } from './cards/cards.js';
import React, { useState, useMemo } from 'react';
import { consortiumChains2 } from '../chains.js';
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { getEditorBaseUrl } from '../../../utils/index.js';
const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  arrows: false,
  pauseOnHover: true,
  dotsClass: chainStyle.dots
};
export function ConsortiumChains() {
  const [cur, setCur] = useState(0);

  const { t } = useTranslation();
  const current = t('menu.lang');

  const { language } = useI18next();
  const ideLang = useMemo(() => {
    return language === 'zh-CN' ? 'zh' : 'en';
  }, [language]);
  return (
    <section className={`${sectionStyle.section} ${chainStyle.chain}`}>
      <div className={chainStyle.flex}>
        <h2 className={`${chainStyle.fixMain} ${chainStyle.header}`}>
          <Trans>consortiumChains</Trans>
        </h2>
      </div>
      <Slider
        {...settings}
        beforeChange={(cur, next) => {
          setCur(next);
        }}
      >
        {consortiumChains2.map((chain) => {
          return (
            <div key={chain.key}>
              <div className={chainStyle.flex}>
                <div
                  className={`${chainStyle.fixMain} ${chainStyle.contentParent}`}
                >
                  <Jumbotron
                    title={
                      current === 'zh' || current === 'zh-CN'
                        ? chain.titleZH
                        : chain.title
                    }
                    content={
                      current === 'zh' || current === 'zh-CN'
                        ? chain.contentZH
                        : chain.content
                    }
                  />
                </div>
                <div
                  className={`${chainStyle.stretch} ${chainStyle.imgParent}`}
                >
                  {chain.largeImg}
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      <p>
        <span
          className={chainStyle.btn}
          onClick={() => {
            window.open(
              `${getEditorBaseUrl()}${
                consortiumChains2[cur].link
              }?language=${ideLang}`
            );
          }}
        >
          <Trans>tryInChainIDE</Trans>
        </span>
      </p>
      <Cards chains={consortiumChains2} cur={cur} pos="right" />
    </section>
  );
}
