import React, { useState, useCallback } from 'react';
import { Menu } from './menu/menu.js';
import { MobileMenu } from './mobileMenu/mobileMenu.js';
import * as style from './header.module.css';
import { MobileMenuContent } from './mobileMenu/mobileMenuContent.js';
import Logo from '../../images/chainide.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { bannerConfig } from '../../banner/index.js';

const imgs = require.context(`../../banner/imgs`, true);
const imgList = imgs.keys().map((item) => imgs(item));
const pcImgName = bannerConfig.pcImg.split('/')[2].split('.')[0];
const mobileImgName = bannerConfig.mobileImg.split('/')[2].split('.')[0];
const pcImg = imgList.find((item) => item.default.includes(pcImgName)).default;
const mobileImg = imgList.find((item) =>
  item.default.includes(mobileImgName)
).default;

export function Header() {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const toggleShow = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      setShow(!show);
    },
    [show]
  );
  const handleBannerClick = useCallback(() => {
    window._hmt.push([
      '_trackEvent',
      'banner-2023-07-14',
      'click',
      'builder-grants'
    ]);
  }, []);
  return (
    <header className={style.header}>
      {bannerConfig.show ? (
        <a
          href={bannerConfig.link}
          target="_blank"
          style={{ cursor: 'pointer' }}
          onClick={handleBannerClick}
        >
          <div className={style.eventsPC}>
            <img className={style.img} src={pcImg} alt="banner" />
          </div>
          <div className={style.eventsMobile}>
            <img className={style.img} src={mobileImg} alt="banner" />
          </div>
        </a>
      ) : (
        <></>
      )}
      <div className={style.headerContent}>
        <a href="/" className={style.logoWrapper}>
          <h1>{t('title')}</h1>
          <Logo />
        </a>
        <Menu />
        <MobileMenu onTab={toggleShow} />
      </div>
      <MobileMenuContent show={show} />
    </header>
  );
}
