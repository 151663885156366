import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

export const SEO = ({ title, description, keywords, pathname, children }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            author
            image
            siteUrl
            twitterUsername
          }
        }
      }
    `
  );
  const {
    title: defaultTitle,
    description: defaultDescription,
    keywords: defaultKeywords,
    image,
    author,
    siteUrl,
    twitterUsername
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    keywords: keywords || defaultKeywords,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
    author
  };

  return (
    <Helmet>
      <title>{seo.title}</title>
      <link rel="icon" href="/favicon.ico" />
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={seo.image} />
      <meta property="og:site_name" content="ChainIDE" />
      <meta property="og:author" content={seo.author} />
      <meta itemprop="name" content="ChainIDE" />
      <meta itemprop="description" content={seo.description} />
      <meta itemprop="image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:domain" content="chainide.com" />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://chainide.com/",
          "email": "contact@whitematrix.io",
          "name": "ChainIDE",
          "logo": "https://chainide.com/chainide.png"
        }
    `}</script>
      <link rel="canonical" href={seo.url} />
      {children}
    </Helmet>
  );
};
