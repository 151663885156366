// extracted by mini-css-extract-plugin
export var block = "blocks-module--block--f6c80";
export var blockList = "blocks-module--blockList--1ec3d";
export var btn = "blocks-module--btn--222df";
export var btnNormal = "blocks-module--btnNormal--f01c8";
export var modalBtnContainer = "blocks-module--modalBtnContainer--0e462";
export var modalCloseBtn = "blocks-module--modalCloseBtn--51e4c";
export var modalContainer = "blocks-module--modalContainer--c275a";
export var modalContainerHide = "blocks-module--modalContainerHide--d4555";
export var modalContainerShow = "blocks-module--modalContainerShow--8c36b";
export var modalDesc = "blocks-module--modalDesc--d01f6";
export var modalTitle = "blocks-module--modalTitle--bb27d";
export var moveLeft = "blocks-module--moveLeft--d41dd";
export var moveLeftSix = "blocks-module--moveLeftSix--b2979";
export var moveRight = "blocks-module--moveRight--dd94e";
export var tryParent = "blocks-module--tryParent--0c3db";