import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import * as sectionStyle from '../section.module.css';
import * as Style from './statistics.module.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const list = [
  {
    key: 'Projects Collaborated',
    desc: 'Projects Collaborated',
    descZH: '区块链项目',
    number: '250,000+'
  },
  {
    key: 'Smart Contract Compiled',
    desc: 'Smart Contract Compiled',
    descZH: '智能合约编译',
    number: '5000000+'
  },
  {
    key: 'Developers',
    desc: 'Developers',
    descZH: '开发者',
    number: '300000+'
  },
  {
    key: 'Save Your Time',
    desc: 'Save Your Time',
    descZH: '开发时间缩短',
    number: '10X'
  }
];

export function Statistics(props) {
  const { t } = useTranslation();
  const current = t('menu.lang');
  return (
    <div className={sectionStyle.section}>
      <div className={Style.statistics}>
        <div className={Style.data}>
          <ul>
            {list.map((l) => (
              <li key={l.key}>
                <div className={Style.number}>{l.number}</div>
                <div className={Style.desc}>
                  {current === 'zh' || current === 'zh-CN' ? l.descZH : l.desc}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className={Style.image}>
          <StaticImage
            src="../../../images/statistics_image.png"
            placeholder="blur"
            alt="statistics image"
            width={632}
            objectFit="contain"
          />
        </div>
      </div>
    </div>
  );
}
