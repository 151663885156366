import { GATSBY_CHAINIDE_EDITOR_ENV } from 'gatsby-env-variables';

const scrollToAnchor = (anchorName, block = 'center') => {
  if (anchorName) {
    // 找到锚点
    const anchorElement = document.getElementById(anchorName);
    // 如果对应id的锚点存在，就跳转到锚点
    if (anchorElement) {
      try {
        anchorElement.scrollIntoView({ block, behavior: 'smooth' });
      } catch (e) {
        // 低版本chrome(<61)
        anchorElement.scrollIntoView();
      }
    }
  }
};

const getEditorBaseUrl = () => {
  const isCN = location.origin.includes('chainide.cn');
  const isDevelop = GATSBY_CHAINIDE_EDITOR_ENV === 'develop';
  return `https://${isDevelop ? 'develop-2egludalf0.' : ''}chainide.${
    isCN ? 'cn' : 'com'
  }/s`;
};

export { scrollToAnchor, getEditorBaseUrl };
