import React from 'react';
import { Tabs } from './tabs/tabs.js';
import * as sectionStyle from '../section.module.css';
import { Trans } from 'gatsby-plugin-react-i18next';

export function Features(props) {
  return (
    <section className={sectionStyle.section}>
      <h2 className={sectionStyle.sectionHeader}>
        <Trans>feature</Trans>
      </h2>
      <Tabs />
    </section>
  );
}
