import React from 'react'
import * as Style from './newsItem.module.css'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export function NewsItem({ news }) {
    const {
        index,
        date,
        title,
        preview,
        content,
        contentZH,
        titleZH,
        link,
        linkZH,
    } = news

    const { t } = useTranslation()
    const current = t('menu.lang')
    return (
      <div className={Style.newsItem}>
        <div className={Style.guide}>
          <div className={Style.index}>{index}</div>
          <div className={Style.date}>{date}</div>
        </div>
        <div className={Style.detail}>
          <div className={Style.header}>
            <span>
              {current === 'zh' || current === 'zh-CN' ? (
                linkZH ? (
                  <a
                    target="_blank"
                    href={linkZH}
                    rel="noreferrer"
                    title={titleZH}
                  >
                    {titleZH}
                  </a>
                ) : (
                  titleZH
                )
              ) : link ? (
                <a target="_blank" href={link} rel="noreferrer" title={title}>
                  {title}
                </a>
              ) : (
                title
              )}
            </span>
          </div>
          <div className={Style.body}>
            {preview && <div className={Style.preview}>{preview}</div>}
            <div className={Style.content}>
              {current === 'zh' || current === 'zh-CN' ? contentZH : content}
            </div>
          </div>
        </div>
      </div>
    );
}
