import React from 'react'
import { useEffect, useRef } from 'react'
import * as Style from './cards.module.css'

export function Cards({ chains = [], cur = 0, pos = 'right' }) {
    const ref = useRef()

    useEffect(() => {
        const parent = ref.current
        if (parent) {
            const last = chains.length - 1

            const next = cur === last ? 0 : cur + 1
            const nextNext = next === last ? 0 : next + 1

            const nodes = parent.childNodes
            for (const d of nodes) {
                if (d.id === `shot${cur}`) {
                    d.className = Style.down
                    continue
                }
                if (d.id === `shot${next}`) {
                    d.className = Style.center
                    continue
                }
                if (d.id === `shot${nextNext}`) {
                    d.className = Style.up
                    continue
                }
                d.className = ''
            }
        }
    }, [chains, cur])

    return (
        <div
            ref={ref}
            className={`${Style.cards} ${pos === 'right' ? Style.right : Style.left
                }`}
        >
            {chains.map((chain, index) => {
                return (
                    <div key={chain.key} id={`shot${index}`}>
                        {chain.screenshot}
                    </div>
                )
            })}
        </div>
    )
}
