import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import * as sectionStyle from '../section.module.css';
import * as Style from './partners.module.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export function Partnerts(props) {
  const { t } = useTranslation();

  return (
    <>
      {t('menu.lang') !== 'en' && (
        <div className={`${sectionStyle.section} ${Style.partner}`}>
          <div className={sectionStyle.sectionHeader}>
            <Trans>visionaries</Trans>
          </div>
          <div className={Style.logo}>
            <div className={Style.logoItem}>
              <StaticImage
                className={Style.staticImage}
                src="./wanxiang.svg"
                placeholder="blur"
                alt="AntChain Logo"
                objectFit="contain"
              />
            </div>
            <div className={Style.logoItem}>
              <StaticImage
                className={Style.staticImage}
                src="../../../images/partners/yifangda@2x.png"
                alt="yifangda logo"
              ></StaticImage>
            </div>
            <div className={Style.logoItem}>
              <StaticImage
                className={Style.staticImage}
                src="../../../images/partners/order@2x.png"
                alt="order logo"
              ></StaticImage>
            </div>
            <div className={Style.logoItem}>
              <StaticImage
                className={Style.staticImage}
                src="../../../images/partners/antGroup@2x.png"
                alt="ant group logo"
              ></StaticImage>
            </div>
            <div className={Style.logoItem}>
              <StaticImage
                className={Style.staticImage}
                src="../../../images/partners/InvestemtGroup@2x.png"
                alt="jiangbeixinqu logo"
              ></StaticImage>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
