import React, { useState } from 'react';
import * as Style from './tabs.module.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

import screenshotImg from '../screenshot.png';

const tabList = ['feature1', 'feature2', 'feature3'];

export function Tabs() {
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation();
  const activeContent = t(`${tabList[activeIndex]}-desc`);
  return (
    <div className={Style.tabs}>
      <div className={Style.header}>
        <ul>
          {tabList.map((feature, index) => {
            return (
              <li
                key={feature}
                className={index === activeIndex ? Style.active : ''}
                onClick={() => setActiveIndex(index)}
                onKeyDown={(ev) => {
                  if (ev.keyCode === 13) {
                    setActiveIndex(index);
                  }
                }}
              >
                <span>{t(feature)}</span>
              </li>
            );
          })}
        </ul>
      </div>
      <p className={Style.content}>{activeContent}</p>
      <img
        src={screenshotImg}
        alt="ChainIDE snapshot"
        style={{ width: '100%' }}
      />
    </div>
  );
}
