import React from 'react';
import * as sectionStyle from '../section.module.css';
import * as newsStyle from './news.module.css';
//import { StaticImage } from 'gatsby-plugin-image'
import { NewsItem } from './newsItem.js';
const newsList = [
  {
    index: '01',
    date: `2022.04.02`,
    title: 'Conflux Labs x ChainIDE : Hydra Developer Bootcamp',
    titleZH:
      'ChainIDE携手Conflux开展HydraDeveloperBootcamp活动，助力“一带一路”非洲国家开发者生态',
    content: `The Hydra Developer Bootcamp event organized by ChainIDE and Conflux for developers in African countries started at 8:00 pm Beijing time on April 2nd.
The event provided African developers with a four-week free blockchain game development course. The Hydra Developer Bootcamp event constructed a high-quality, advanced technology training platform for developers in the "Belt and Road" African countries. It created a good atmosphere for blockchain projects. Also, it trained a lot of practical blockchain industry talents who understand technology and development for African countries.`,
    contentZH: `ChainIDE携手Conflux为非洲国家开发者举办的HydraDeveloperBootcamp活动将于北京时间4月2日晚上8点开始。本次活动预计为非洲开发者们提供为期四周的免费区块链游戏开发课程。HydraDeveloperBootcamp活动致力于为“一带一路”非洲国家开发者们搭建高质、纯粹的培训平台，并为其提供良好的区块链项目开发氛围，为非洲国家培训一批懂技术懂开发的实用型区块链行业人才。`,
    link: 'https://mp.weixin.qq.com/s/Vexzu4N1IR2ohBDUWCX4rQ',
    linkZH: 'https://mp.weixin.qq.com/s/Vexzu4N1IR2ohBDUWCX4rQ'
  },
  {
    index: '02',
    date: `2022.03.19`,
    title: 'THUBA Comprehensive Blockchain Bootcamp',
    titleZH:
      '清华大学学生区块链协会THUBA举办区块链综合训练营，ChainIDE提供技术与生态支持',
    content: `The THUBA Blockchain Comprehensive Bootcamp was hosted by the Tsinghua University Student Blockchain Association. Beihang Blockchain Association, Chinese University of Hong Kong (Shenzhen) Blockchain Association, Fudan University School of Management MBA Blockchain Club, Peking University Blockchain Association, Renmin University of China Student Blockchain Association, Shanghai Jiaotong University Blockchain Association, Zhejiang University Student Blockchain Association participated in co-organizing this event. ChainIDE provided technical and ecological support. This event will involved two aspects of blockchain basic technology and multi-disciplinary practical application, and provided participants with 10-week blockchain technology and application courses.`,
    contentZH: (
      <p>
        由清华大学学生区块链协会主办，北航区块链协会、香港中文大学（深圳）区块链协会、复旦大学管理学院MBA区块链俱乐部、北京大学区块链协会、中国人民大学学生区块链协会、上海交通大学区块链协会、浙江大学学生区块链协会参与协办，ChainIDE提供技术与生态支持的THUBA区块链综合训练营于3月19日正式开展。本次活动将涉及区块链基础技术、多学科实际应用两方面，为参与者提供为期10周的区块链技术与应用课程。
        {/* <a
                    href="https://mp.weixin.qq.com/s/uA2z4O6jC9FCBzrSaJArkg"
                    target="_blank"
                    rel="noreferrer"
                >
                    2021年4月27日
                </a>
                ，BMDS2021在线研讨会顺利召开，该系列研讨会主要围绕区块链与数据科学领域进行研究与分享，主要针对区块链知识和多媒体数据科学的交叉融合，进行深度研讨，碰撞出思维的火花。
                <br />
                <a
                    href="https://mp.weixin.qq.com/s/kbCJUROKpl7wfjSjUMXIiA"
                    target="_blank"
                    rel="noreferrer"
                >
                    2021年4月28日
                </a>
                ，BMDS2021第二日在线研讨会按时举行，参会嘉宾有南京纯白矩阵科技有限公司总经理吴啸、香港中文大学（深圳）人云系统实验室主任蔡玮博士和阿尔伯塔大学的计算机科学教授Anup
                Basu博士。
                <br />
                <a
                    href="https://mp.weixin.qq.com/s/_6j2S1uV1JCPi5Od0axkvg"
                    target="_blank"
                    rel="noreferrer"
                >
                    2021年4月29日
                </a>
                ，BMDS2021第三日在线研讨会按时举行，参会嘉宾有Health
                Gauge 联合创始人Brunce
                Matichuk、不列颠哥伦比亚大学计算机与科学学院教授王泽华博士以及纯白矩阵技术科学家孙昕尧。
                <br />
                <a
                    href="https://mp.weixin.qq.com/s/EOGieCYxcmEm5AMHTXMYgg"
                    target="_blank"
                    rel="noreferrer"
                >
                    2021年4月30日
                </a>
                ，BMDS2021第四日在线研讨会按时举行，参会嘉宾有加拿大多伦多花旗银行副总裁Neha
                Raj、蚂蚁集团投资总监兼A&T资本管理合伙人于隽、BestBuy市场研发部的首席工程师Aaron
                Liu以及Nanopay的COO Carlos，共同探讨关于产业机会话题。 */}
      </p>
    ),
    link: 'https://mp.weixin.qq.com/s/3PzG5LF3l15M3jTgilFc0Q',
    linkZH: 'https://mp.weixin.qq.com/s/3PzG5LF3l15M3jTgilFc0Q'
  },
  {
    index: '03',
    date: '2022.02.11',
    title: '2022 BNBChain & ChainIDE Developer Bootcamp',
    titleZH: '2022 BNBChain & ChainIDE全球开发者线上训练营正式开启',
    content: `The 2022 BNBChain & ChainIDE Global Developer Bootcamp officially launched on Feburary 11.
This bootcamp provided a 3-week course. The course content covered digital art and NFTs, dApp teaching, blockchain game development, metaverse construction, blockchain security, and other modules. The event created an immersive dApp development experience for junior developers. At the same time, it helped to improve developers' cognition and development capabilities of applications such as blockchain games.`,
    contentZH: (
      <p>
        BNB Chain与纯白矩阵联合主办的2022 BNB
        Chain&ChainIDE全球开发者集训营于2月11日
        正式开启。本次训练营提供为期3周的课程，涉及数字藏品与NFT、dApp教学、区块链游戏开发、元宇宙建设、区块链安全性等多个主题模块，旨在为初级开发者们打造沉浸式dApp开发体验，全方位提升开发者对区块链游戏等应用的认知和开发能力。
        {/* <a
                    href="https://mp.weixin.qq.com/s/9jsnCWJ5XYxXQ74-o8FiBg"
                    target="_blank"
                    rel="noreferrer"
                >

                </a> */}
        {/* <br />
                <a
                    href="https://mp.weixin.qq.com/s/UjSwxLRGcwm5wgzASe8QIg"
                    target="_blank"
                    rel="noreferrer"
                >
                    2021年2月6日
                </a>
                ，纯白矩阵创始人吴啸为非洲开发者进行了以”Blockchain:Technology,
                Innovation and
                Thinking”为主题的讲座。这也是非洲Masterclass公开课的第一节课程。
                <br />
                <a
                    href="https://mp.weixin.qq.com/s/bbTYkasgOFfbedEp8O7Bpg"
                    target="_blank"
                    rel="noreferrer"
                >
                    2021年2月20日
                </a>
                ，纯白矩阵CTO张舒艺受邀参加第三周非洲Masterclass公开课，此次公开课以“Decentralized
                Application”为主题，为非洲开发者分享关于智能合约与EVM的内容。
                <br />
                <a
                    href="https://mp.weixin.qq.com/s/uxKXOznxQuFpaONBQyTm1w"
                    target="_blank"
                    rel="noreferrer"
                >
                    2021年3月8日
                </a>
                ，纯白矩阵生态负责人赵伊磊受邀参加非洲Masterclass公开课，此次公开课以”What’s
                Defi?How to build a Defi
                app?”为主题，介绍了关于Defi应用和生态当中的一些内容。 */}
      </p>
    ),
    link: 'https://mp.weixin.qq.com/s/9jsnCWJ5XYxXQ74-o8FiBg',
    linkZH: 'https://mp.weixin.qq.com/s/9jsnCWJ5XYxXQ74-o8FiBg'
  }
];

export function News() {
  return (
    <section className={`${sectionStyle.section} ${newsStyle.news}`}>
      <h2 className={sectionStyle.sectionHeader}>NEWS</h2>
      <div>
        {newsList.map((news) => {
          return <NewsItem key={news.index} news={news} />;
        })}
      </div>
    </section>
  );
}
