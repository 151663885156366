import React from 'react'
import * as Style from './jumbotron.module.css'
export function Jumbotron({ title = '', content = '' }) {
    return (
        <div className={Style.jumbotron}>
            <h3>{title}</h3>
            <p className={Style.content}>{content}</p>
        </div>
    )
}
