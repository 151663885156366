import { StaticImage } from 'gatsby-plugin-image';
import React, { useCallback, useEffect, useState } from 'react';
import * as style from './menu.module.css';
import {
  Link,
  useI18next,
  Trans,
  useTranslation
} from 'gatsby-plugin-react-i18next';

import TwitterIcon from '../../../images/ui/twitter.svg';
import DiscordIcon from '../../../images/ui/discord.svg';
import TelegramIcon from '../../../images/ui/telegram.svg';

export const langMap = { en: 'ENGLISH', 'zh-CN': '中文' };
export function Menu() {
  const [show, setShow] = useState(false);

  const _toggleLanguageList = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      setShow(!show);
    },
    [show]
  );

  useEffect(() => {
    const hideLanguageList = () => {
      setShow(false);
    };

    document.addEventListener('click', hideLanguageList);
    return () => {
      document.removeEventListener('click', hideLanguageList);
    };
  }, []);
  const { t } = useTranslation();
  const { languages, originalPath } = useI18next();
  const current = t('menu.lang');
  return (
    <div className={style.menu}>
      <a href="https://chainide.com/events/">
        <span>
          <Trans>menu.events</Trans>
        </span>
      </a>
      {/* <a href={t('menu.incubator.url')} rel="noreferrer" target="_blank">
        <span>
          <Trans>menu.incubator</Trans>
        </span>
      </a> */}
      <a href={t('menu.forum.url')} rel="noreferrer" target="_blank">
        <span>
          <Trans>menu.forum</Trans>
        </span>
      </a>
      <a href={t('menu.document.url')} rel="noreferrer" target="_blank">
        <span>
          <Trans>menu.document</Trans>
        </span>
      </a>
      <span
        className={style.language}
        onClick={_toggleLanguageList}
        onKeyDown={(ev) => {
          if (ev.keyCode === 13) {
            _toggleLanguageList();
          }
        }}
      >
        <span>{langMap[current]}</span>
        <StaticImage
          src="../../../images/ui/arrow.png"
          placeholder="blur"
          alt="arrow down"
          width={13}
          objectFit="contain"
        />
        {show && (
          <div className={style.menuList}>
            <ul>
              {languages.map((lng) => (
                <li key={lng} className={current === lng ? style.active : ''}>
                  <Link to={originalPath} language={lng}>
                    {langMap[lng]}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </span>

      <span className={style.social_links}>
        <a
          href="https://twitter.com/ChainIde"
          target="_blank"
          rel="noreferrer"
          className={style.twitter}
        >
          <TwitterIcon />
        </a>

        <a
          href="https://discord.gg/qv8M29pYwX"
          target="_blank"
          rel="noreferrer"
          className={style.discord}
        >
          <DiscordIcon />
        </a>

        <a
          href="https://t.me/ChainIDEdevelopers"
          target="_blank"
          rel="noreferrer"
          className={style.telegram}
        >
          <TelegramIcon />
        </a>
      </span>
    </div>
  );
}
